<template>
  <div>
    <b-table
      striped
      hover
      :items="productDataList"
      :fields="fields"
      :busy="isLoadingComponent"
      sort-by="no"
      show-empty
      responsive
    >
      <template #table-busy>
        <div class="text-center text-secondary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:cell(no)="{ index }">
        {{ (currentPageList - 1) * 10 + index + 1 }}
      </template>

      <template v-slot:cell(images)="{ item }">
        <div class="d-flex flex-column" style="gap: 1rem">
          <img
            :src="item.images[0]"
            class="rounded"
            style="height: 150px; object-fit: cover"
            :alt="item.name"
            @error="
              $event.target.src = require(`@/assets/images/avatars/no-image.png`)
            "
          />
        </div>
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-dropdown variant="danger" size="sm">
          <template #button-content> Action </template>
          <b-dropdown-item @click="editItem(item)">
            <feather-icon icon="EditIcon" class="mr-75" />
            Update
          </b-dropdown-item>
          <b-dropdown-item @click="deleteItem(item.uuid)">
            <feather-icon icon="TrashIcon" class="mr-75" />
            Delete
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-modal
      id="form-product"
      centered
      title="Edit Product"
      size="lg"
      no-close-on-backdrop
    >
      <ValidationObserver>
        <div class="form">
          <div class="row mb-2">
            <b-col cols="12">
              <div class="form-group">
                <label>Name:</label>
                <validation-provider
                  name="name"
                  rules="required"
                  v-slot="{ errors, classes }"
                >
                  <input
                    type="text"
                    class="form-control"
                    :class="classes"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Nama Product"
                    v-model="formPayloadProduct.name"
                  />
                  <small
                    v-for="(validation, index) in validationsForm.name"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  >
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="form-group">
                <label for="description">Description:</label>
                <validation-provider
                  name="description"
                  rules="required"
                  v-slot="{ errors, classes }"
                >
                  <textarea
                    id="description"
                    type="text"
                    class="form-control"
                    style="min-height: 200px"
                    :class="classes"
                    :state="errors.length > 0 ? false : null"
                    v-model="formPayloadProduct.description"
                    placeholder="Deskripsi"
                  />
                  <small
                    v-for="(validation, index) in validationsForm.description"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  >
                </validation-provider>
              </div>
            </b-col>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <h3 class="mb-0">Product Image</h3>
            <b-button
              variant="primary"
              class="btn waves-effect waves-float waves-light btn-primary"
              @click="addImages()"
              :disabled="formPayloadProduct.images.length >= 3"
            >
              Add Images
            </b-button>
          </div>
          <b-row class="mt-2">
            <b-col
              cols="12"
              lg="4"
              v-for="(images, index) in formPayloadProduct.images"
              :key="index"
            >
              <div class="form-group">
                <div
                  class="d-flex justify-content-between align-items-center mb-1"
                >
                  <label :for="'image' + index + 1" class="mb-0"
                    >Image {{ index + 1 }}</label
                  >
                  <b-button
                    variant="danger"
                    class="btn waves-effect waves-float waves-light btn-danger button__delete"
                    @click="removeItem(index)"
                    v-show="index > 0"
                  >
                    <trash-icon size="1x" class="custom-class"></trash-icon>
                  </b-button>
                </div>
                <b-form-file
                  :id="'image' + index + 1"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  ref="fileInput"
                  accept="image/png, image/jpeg,"
                  @change="handleFileChange($event, index)"
                />
                <small
                  v-for="(validation, index) in validationsForm[
                    'images.' + index + '.file'
                  ]"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
                <div
                  v-if="formPayloadProduct.images[index].preview"
                  class="mt-1"
                >
                  <img
                    :src="formPayloadProduct.images[index].preview"
                    class="w-100 product__image rounded"
                  />
                </div>
              </div>
              <input type="hidden" v-model="images.position" />
            </b-col>
          </b-row>
        </div>
      </ValidationObserver>
      <template #modal-footer="{}">
        <b-button
          variant="primary"
          @click="updateItem()"
          v-bind:disabled="isLoading"
        >
          Edit Product
        </b-button>
      </template>
    </b-modal>

    <div
      v-if="resultData.itemsPerPage > 0"
      class="mt-3 d-flex justify-content-between align-items-center"
    >
      <small
        >Showing {{ (resultData.currentPage - 1) * 10 + 1 }} to
        {{ (resultData.currentPage - 1) * 10 + 1 * resultData.itemCount }} from
        {{ resultData.totalItems }}</small
      >
      <b-pagination
        class="justify-content-end"
        v-model="currentPageList"
        :total-rows="resultData.totalItems"
        :per-page="resultData.itemsPerPage"
        aria-controls="my-table"
        @change="getData"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import { TrashIcon } from "vue-feather-icons";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BTable,
  BSpinner,
  BBadge,
  BImg,
  BDropdown,
  BPagination,
  BDropdownItem,
  BButton,
  BCol,
  BRow,
  BFormFile,
  VBModal,
} from "bootstrap-vue";
import _ from "lodash";
export default {
  components: {
    BTable,
    BSpinner,
    BBadge,
    BImg,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BCol,
    BRow,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    TrashIcon,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    productData: {
      type: "",
    },
    formPayload: {
      type: "",
    },
    validations: {
      type: "",
    },
    editId: {
      type: "",
    },
    result: {
      type: "",
    },
    validations: {
      type: "",
    },
    isLoading: {
      type: Boolean,
    },
    currentPage: {
      type: Number,
    },
    getData: {
      type: Function,
    },
    deleteItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    updateItem: {
      type: Function,
    },
    addImages: {
      type: Function,
    },
    removeItem: {
      type: Function,
    },
    handleFileChange: {
      type: Function,
    },
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value;
    },
    productData(value) {
      this.productDataList = value;
    },
    formPayload(value) {
      this.formPayloadProduct = value;
    },
    validations(value) {
      this.validationsForm = value;
    },
    currentPage(value) {
      this.currentPageList = value;
    },
    result(value) {
      this.resultData = value;
    },
  },
  data() {
    return {
      required,
      isLoadingComponent: false,
      currentPageList: 1,
      resultData: {},
      productDataList: [],
      formPayloadProduct: {
        name: "",
        description: "",
        images: [
          {
            file: null,
            position: "",
          },
        ],
      },
      fields: [
        { key: "no" },
        { key: "name" },
        { key: "description" },
        { key: "images" },
        { key: "actions" },
      ],
      validationsForm: "",
      //   filter: {
      //     order_type: "desc",
      //   },
    };
  },
  computed: {
    rows() {
      return this.productDataList.length;
    },
  },

  methods: {},
};
</script>

<style>
.button__delete {
  padding: 2px !important;
}
.product__image {
  height: 210px;
  object-fit: cover;
}
</style>
