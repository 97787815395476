<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card title="Profil Toko">
          <div class="w-100 text-center" v-if="isLoading">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
          <b-row class="relative" v-else>
            <b-button
              v-if="detailStore.status !== 'approved'"
              v-b-modal.form-approve
              variant="primary"
              class="d-none d-md-block btn waves-effect waves-float waves-light btn-primary"
              style="position: absolute; top: 1.5rem; right: 1.5rem"
            >
              Aprove Form
            </b-button>
            <b-col cols="12" class="mb-4">
              <div class="d-flex justify-content-center">
                <img
                  :src="
                    detailStore.image
                      ? detailStore.image
                      : require(`@/assets/images/avatars/null-profile.webp`)
                  "
                  @error="
                    $event.target.src = require(`@/assets/images/avatars/no-image.png`)
                  "
                  :alt="detailStore.name"
                  class="rounded-pill"
                  style="height: 150px; width: 150px; object-fit: cover"
                />
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50"
                >
                  <h5 class="mb-0">Nama Toko</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <p class="mb-0 w-100">{{ detailStore.name }}</p>
              </div>
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50"
                >
                  <h5 class="mb-0">Nama Owner</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <p class="mb-0 w-100">{{ detailStore.owner_name }}</p>
              </div>
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50"
                >
                  <h5 class="mb-0">Deskripsi</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <p class="mb-0 w-100">{{ detailStore.description }}</p>
              </div>
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50"
                >
                  <h5 class="mb-0">Status</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <div class="w-100">
                  <b-badge
                    pill
                    variant="light-success"
                    class="text-capitalize"
                    v-if="detailStore.status === 'approved'"
                  >
                    {{
                      detailStore.status
                        ? detailStore.status.replaceAll("_", " ").toLowerCase()
                        : ""
                    }}
                  </b-badge>
                  <b-badge
                    pill
                    variant="light-warning"
                    class="text-capitalize"
                    v-else-if="detailStore.status === 'need_approval'"
                  >
                    {{
                      detailStore.status
                        ? detailStore.status.replaceAll("_", " ").toLowerCase()
                        : ""
                    }}
                  </b-badge>
                  <b-badge
                    pill
                    variant="light-danger"
                    class="text-capitalize"
                    v-else
                  >
                    {{
                      detailStore.status
                        ? detailStore.status.replaceAll("_", " ").toLowerCase()
                        : ""
                    }}
                  </b-badge>
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50 align-self-lg-start"
                >
                  <h5 class="mb-0">Aktifitas</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <div class="w-100">
                  <ul class="p-0">
                    <li>
                      {{
                        detailStore.counting ? detailStore.counting.like : "-"
                      }}
                      Likes
                    </li>
                    <li>
                      {{
                        detailStore.counting
                          ? detailStore.counting.comment
                          : "-"
                      }}
                      Comments
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between align-self-lg-start w-50"
                >
                  <h5 class="mb-0">Online Stores</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <div class="w-100" style="max-width: 64%">
                  <ul class="p-0">
                    <li
                      v-for="onlineStore in detailStore.online_stores"
                      :key="onlineStore.platform"
                    >
                      <p class="mb-0">{{ onlineStore.platform }}</p>
                      <a :href="onlineStore.url" target="_blank" class="mb-0">{{
                        onlineStore.url
                      }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between align-self-lg-start w-50"
                >
                  <h5 class="mb-0">Social Media</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <div class="w-100">
                  <ul class="p-0">
                    <li
                      v-for="socialMedia in detailStore.social_media"
                      :key="socialMedia.platform"
                    >
                      <p class="mb-0">{{ socialMedia.platform }}</p>
                      <a :href="socialMedia.url" target="_blank" class="mb-0">{{
                        socialMedia.url
                      }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-modal
            id="form-approve"
            centered
            title="Approval Store"
            size="lg"
            no-close-on-backdrop
          >
            <div class="form">
              <div class="row mb-2">
                <b-col cols="12">
                  <input type="hidden" v-model="formApprove.uuid" />
                  <div class="form-group">
                    <label for="action">Action:</label>
                    <v-select
                      id="action"
                      label="name"
                      v-model="formApprove.action"
                      :options="actionList"
                      placeholder="-- Pilih --"
                      :reduce="(option) => option.value"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    />
                    <small
                      v-for="(validation, index) in validations.action"
                      :key="`errorName${index}`"
                      class="text-danger"
                      >{{ validation }}</small
                    >
                  </div>
                  <div
                    class="form-group"
                    v-if="
                      formApprove.action === 'approved' ||
                      formApprove.action === 'rejected_because_product'
                    "
                  >
                    <label for="group"
                      >Pilih Product
                      {{
                        formApprove.action === "approved" ? "Approve" : "Reject"
                      }}
                      yang akan dipilih:</label
                    >
                    <v-select
                      id="group"
                      label="name"
                      v-model="formApprove.productList"
                      :options="productData"
                      placeholder="-- Pilih --"
                      multiple
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    />
                    <small
                      v-for="(validation, index) in validations.product_uuids"
                      :key="`errorName${index}`"
                      class="text-danger"
                      >{{ validation }}</small
                    >
                  </div>
                </b-col>
              </div>
            </div>

            <template #modal-footer="{}">
              <b-button
                variant="primary"
                @click="approveForm()"
                v-bind:disabled="isLoading"
              >
                Submit
              </b-button>
            </template>
          </b-modal>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-card title="Profil User">
          <div class="w-100 text-center" v-if="isLoading">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
          <b-row v-else>
            <b-col cols="12" class="mb-4">
              <div class="d-flex justify-content-center">
                <img
                  :src="
                    detailStore.user && detailStore.user.avatar
                      ? detailStore.user.avatar
                      : require(`@/assets/images/avatars/null-profile.webp`)
                  "
                  @error="
                    $event.target.src = require(`@/assets/images/avatars/no-image.png`)
                  "
                  :alt="detailStore.user ? detailStore.user.name : 'User'"
                  class="rounded-pill"
                  style="height: 150px; width: 150px; object-fit: cover"
                />
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50"
                >
                  <h5 class="mb-0">Nama</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <p class="mb-0 w-100">
                  {{ detailStore.user ? detailStore.user.name : "-" }}
                </p>
              </div>
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50"
                >
                  <h5 class="mb-0">Bio</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <p class="mb-0 w-100">
                  {{ detailStore.user ? detailStore.user.biodata.bio : "-" }}
                </p>
              </div>
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50"
                >
                  <h5 class="mb-0">Perusahaan</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <p class="mb-0 w-100">
                  {{
                    detailStore.user ? detailStore.user.biodata.company : "-"
                  }}
                </p>
              </div>
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50"
                >
                  <h5 class="mb-0">Tier</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <p class="mb-0 w-100 text-capitalize">
                  {{ detailStore.user ? detailStore.user.biodata.tier : "-" }}
                </p>
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50"
                >
                  <h5 class="mb-0">Profesi</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <p class="mb-0 w-100">
                  {{
                    detailStore.user ? detailStore.user.biodata.profession : "-"
                  }}
                </p>
              </div>
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50"
                >
                  <h5 class="mb-0">Akun Bisnis Instagram</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <div class="w-100">
                  <a
                    v-if="
                      detailStore.user &&
                      detailStore.user.biodata.instagram_business_account
                    "
                    :href="
                      'https://www.instagram.com/' +
                      detailStore.user.biodata.instagram_business_account.replace(
                        /^@/,
                        ''
                      ) +
                      '/'
                    "
                    target="_blank"
                    class="mb-0 w-100"
                  >
                    {{
                      detailStore.user
                        ? detailStore.user.biodata.instagram_business_account
                        : "-"
                    }}
                  </a>
                </div>
              </div>
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50"
                >
                  <h5 class="mb-0">Email</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <div class="w-100">
                  <a
                    v-if="detailStore.user && detailStore.user.email"
                    :href="'mailto:' + detailStore.user.email"
                    target="_top"
                    class="mb-0 w-100"
                  >
                    {{ detailStore.user.email }}
                  </a>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-card title="Product List">
      <TableProduct
        :result="result"
        :productData="productData"
        :is-loading="isLoading"
        :get-data="getData"
        :current-page="currentPage"
        :formPayload="formPayload"
        :edit-item="editItem"
        :update-item="updateItem"
        :addImages="addImages"
        :removeItem="removeItem"
        :deleteItem="deleteItem"
        :handleFileChange="handleFileChange"
        :validations="validations"
      />
    </b-card>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import TableProduct from "@/components/toko-sister/TableProduct.vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BCard,
  BFormGroup,
  BFormRadio,
  BButton,
  BRow,
  BSpinner,
  BCol,
  BFormFile,
  BFormCheckbox,
  BBadge,
  VBModal,
} from "bootstrap-vue";
import { successNotification, errorNotification } from "@/auth/utils";
export default {
  components: {
    BCard,
    BRow,
    BSpinner,
    BCol,
    BFormGroup,
    BFormRadio,
    BFormCheckbox,
    BFormFile,
    vSelect,
    BButton,
    BBadge,
    TableProduct,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      currentPage: 1,
      detailStore: {},
      result: [],
      productData: [],
      formPayload: {
        name: "",
        description: "",
        images: [],
      },
      formApprove: {
        uuid: "",
        action: "",
        product_uuids: [],
        productlist: [],
      },
      actionList: [
        {
          name: "Approved",
          value: "approved",
        },
        {
          name: "Rejected Because Product",
          value: "rejected_because_product",
        },
        {
          name: "Rejected Because Store",
          value: "rejected_because_store",
        },
        {
          name: "Rejected Because Description",
          value: "rejected_because_description",
        },
      ],
      isLoading: false,
      validations: "",
    };
  },

  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },

  created() {
    this.loadDetail();
  },

  methods: {
    loadDetail() {
      const params = this.$route.params.uuid;
      this.$http
        .get("/api/v1/admin/sister-store/" + params)
        .then((response) => {
          this.detailStore = response.data.data;
          this.getData();
        });
    },
    getData(page) {
      const slug = this.detailStore.slug;
      this.isLoading = true;
      this.currentPage = page;
      this.$http
        .get(`/api/v1/admin/sister-product-store?store_slug=${slug}`)
        .then((response) => {
          this.result = response.data.data.meta;
          this.productData = response.data.data.items;
          this.currentPage = response.data.data.meta.currentPage;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cleanUpForm() {
      this.editId = null;
      this.formPayload = {
        name: "",
        description: "",
        images: [],
      };
      this.validations = "";
    },
    editItem(item) {
      (this.editId = item.uuid),
        (this.formPayload = {
          name: item.name,
          description: item.description,
          images: [],
        });
      for (let index = 0; index < item.images.length; index++) {
        const imagesItem = item.images[index];
        const imagesData = {
          file: imagesItem,
          position: index,
          preview: imagesItem,
        };
        this.formPayload.images.push(imagesData);
      }
      this.$bvModal.show("form-product");
    },
    addImages() {
      if (this.formPayload.images.length + 1 <= 3) {
        this.formPayload.images.push({
          position: this.formPayload.images.length,
          file: null,
          preview: null,
        });
      }
    },
    removeItem(index) {
      this.formPayload.images.splice(index, 1);
      this.updatePositions();
    },
    updatePositions() {
      this.formPayload.images.forEach((image, index) => {
        image.position = index;
      });
    },
    handleFileChange(event, index) {
      const file = event.target.files[0];
      this.formPayload.images[index].file = file;
      this.formPayload.images[index].preview = URL.createObjectURL(file);
    },
    // showPhoto(file, index) {
    //   this.formPayload.images[index].preview = URL.createObjectURL(file);
    // },
    preparePayload() {
      const form = new FormData();
      for (const key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key)) {
          if (key === "images") {
            this.formPayload.images.forEach((images, index) => {
              Object.keys(images).forEach((key) => {
                if (key !== "preview") {
                  form.append(
                    "images" + "[" + index + "][" + key + "]",
                    images[key]
                  );
                }
              });
            });
          } else {
            form.append(key, this.formPayload[key]);
          }
        }
      }
      return form;
    },
    approveForm() {
      this.isLoading = true;
      const uuid = this.$route.params.uuid;
      const { action } = this.formApprove;
      const productUuids =
        this.formApprove.productList?.map((product) => product.uuid) || [];
      const payload = {
        uuid,
        action,
        product_uuids: productUuids,
      };
      this.$http
        .post("/api/v1/admin/sister-store/action/approval", payload)
        .then((response) => {
          this.isLoading = false;
          this.formApprove = {};
          this.loadDetail();
          successNotification(this, "Success", "Product sudah divalidasi!");
          this.$bvModal.hide("form-approve");
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.meta.messages) {
            errorNotification(this, "Error", error.response.data.meta.messages);
          } else if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
          }
        });
    },
    updateItem() {
      var form = this.preparePayload();
      this.isLoading = true;
      this.$http
        .post(`/api/v1/admin/sister-product-store/${this.editId}/update`, form)
        .then((response) => {
          this.$bvModal.hide("form-product");
          this.getData();
          successNotification(
            this,
            "Success",
            "Product Image successfully updated!"
          );
          this.cleanUpForm();
          this.isLoading = false;
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.meta &&
            error.response.data.meta.messages
          ) {
            errorNotification(this, "Error", error.response.data.meta.messages);
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.meta &&
            error.response.data.meta.validations
          ) {
            this.validations = error.response.data.meta.validations;
            errorNotification(
              this,
              "Error",
              "Form Tidak Boleh ada yang kosong"
            );
          }
          this.isLoading = false;
        });
    },
    deleteItem(slug) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to delete this Product?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$http
            .delete(`/api/v1/admin/sister-product-store/${slug}/delete`)
            .then((response) => {
              this.getData(this.currentPage);
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "Product successfully deleted",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
